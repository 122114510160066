@import 'styles/scoped';

.main {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    list-style-type: none;
    text-transform: uppercase;
    color: $faded;

    li {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.center {
    justify-content: center;
}

.flag {
    display: block;
    width: rem(22px);
    height: rem(22px);
    overflow: hidden;
    border-radius: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        width: get-vw(22px);
        height: get-vw(22px);
    }
}

.flagImg {
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
}

.small {
    width: rem(12px);
    height: rem(12px);

    @include mq(null, xl) {
        width: get-vw(12px);
        height: get-vw(12px);
    }
}
