/**
 * Colors
 */
$white: #ffffff;
$white-50: rgba($white, 0.5);
$black: #090909;
$black-0: rgba($black, 0);
$black-10: rgba($black, 0.1);
$black-20: rgba($black, 0.2);
$black-30: rgba($black, 0.3);
$black-40: rgba($black, 0.4);
$black-50: rgba($black, 0.5);
$black-60: rgba($black, 0.6);
$black-70: rgba($black, 0.7);
$black-80: rgba($black, 0.8);
$black-90: rgba($black, 0.9);
$black-100: rgba($black, 1);
$red: #cd0039;
$beige: #fff3e0;
$smoke: #fffaf3;
$gray: #2c2b2b;
$striked: #b3b3b3;
$light-gray: #f5f5f5;
$faded: #808080;
$maize: #f6d7a4;
$blue: #1a4a88;
$pink: #ffceda;
$green: #00870d;
$orange: #eb995e;
