/**
 * Hover support mixin
 * Detect if device supports hover interaction
 * 1. If device is not supporting hover, add properties as active state styles
 * 2. If device is supporting hover, add properties as hover state styles
 * usage:
 * @include hover{}
 */
@mixin hover() {
    @media (hover: none) {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:active {
            /* 1 */
            @content;
        }
    }

    @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:hover {
            /* 2 */
            @content;
        }
    }
}
