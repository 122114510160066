@import 'styles/scoped';

.underlineLink {
    color: $red;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: top;
    transition: getCustomTransition(color);

    /* invisible hover area so padding dont have to be used for extended hit area */
    &::before {
        content: '';
        position: absolute;
        width: 120%;
        height: 200%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        height: 2px;
        width: 100%;
        background: $black;
        z-index: getZindex('default', 1);
        transform: scaleX(0);
        transform-origin: left;

        @include mq(null, xl) {
            height: get-vw(2px);
        }
    }

    @include hover {
        color: $black;

        &::after {
            transition: getCustomTransition(transform 0.3s);
            transform: scaleX(1);
        }

        .line {
            transition: getCustomTransition(opacity 0.2s linear);
            opacity: 0;
        }
    }

    + .underlineLink {
        @include spacing('margin-left', 's-24');
    }
}

.line {
    position: absolute;
    left: 0;
    top: 100%;
    height: 2px;
    width: 100%;
    background: currentColor;
    z-index: getZindex('default');

    @include mq(null, xl) {
        height: get-vw(2px);
    }
}

.white {
    color: $white;
}
