@import 'styles/scoped';

.footer {
    @include spacing('padding-top', 's-64');
    @include spacing('padding-bottom', 's-24');

    width: 100%;
    display: block;
    background-color: $white;
    border-top: 2px solid $beige;

    @include mq(null, xl) {
        border-top: get-vw(2px) solid $beige;
    }
}

.top {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;

    @include mq(md) {
        display: block;
    }

    a {
        color: inherit;

        @include hover {
            color: $red;
        }
    }
}

.logo {
    @include spacing('width', 's-80');

    display: block;

    @include mq(md) {
        @include spacing('padding-bottom', 's-64');

        margin: 0 auto;
    }

    img {
        width: 100%;
        display: block;
    }
}

.col {
    @include spacing('padding-left', 's-16');

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

    @include mq(md) {
        display: block;
        padding-left: 0;
    }
}

.nav {
    @include spacing('padding-left', 's-32');
    @include spacing('padding-right', 's-32');
    @include spacing('padding-bottom', 's-32');

    display: block;
    list-style-type: none;

    @include mq(md) {
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    &:first-child {
        padding-left: 0;
    }
}

.navLink {
    @include spacing('padding-bottom', 's-16');

    display: block;

    &:last-child {
        padding-bottom: 0;
    }
}

.logos {
    width: get-vw(180px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;

    @include mq(lg) {
        width: get-vw(240px);
    }

    @include mq(md) {
        width: 100%;
        max-width: rem(180px);
        margin: 0 auto;
        justify-content: center;
    }

    li {
        @include spacing('margin-top', 's-8');
        @include spacing('margin-right', 's-8');

        @include mq(md) {
            @include spacing('margin-left', 's-4');
            @include spacing('margin-right', 's-4');
        }

        a {
            @include spacing('padding', 's-8');

            display: flex;
            align-items: center;
            justify-content: center;
            width: get-vw(74px);
            height: get-vw(40px);
            border: 1px solid rgba($faded, 0.3);
            border-radius: 3px;

            @include hover {
                border-color: $red;
            }

            @include mq(null, xl) {
                border: get-vw(1px) solid rgba($faded, 0.3);
                border-radius: get-vw(3px);
            }

            @include mq(lg) {
                width: rem(74px);
                height: rem(40px);
            }
        }

        img {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
        }
    }
}

.bottom {
    @include spacing('padding-top', 's-120');

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    color: $faded;

    @include mq(md) {
        @include spacing('padding-top', 's-40');

        display: block;
        text-align: center;
    }

    a {
        transition: getTransition();

        @include hover {
            color: $red;
        }
    }
}

.bottomNav {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;

    li {
        @include spacing('margin-right', 's-40');

        @include mq(md) {
            @include spacing('margin-bottom', 's-16');

            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @include mq(md) {
        display: block;
    }
}
