@import 'styles/scoped';

.o-container {
    width: 100%;
    padding: 0 percentage(3, 24);
    max-width: rem(4320px);
    margin: 0 auto;

    @include mq(lg) {
        padding: 0 percentage(2, 24);
    }

    @include mq(md) {
        padding: 0 percentage(2, 24);
    }

    &--full-width {
        padding: 0;

        @include mq(md) {
            padding: 0 percentage(2, 24);
        }
    }

    &--wide {
        padding: 0 percentage(1, 24);

        @include mq(md) {
            padding: 0 percentage(2, 24);
        }
    }

    &--narrow {
        padding: 0 percentage(2, 24);

        @include mq(md) {
            padding: 0 percentage(2, 24);
        }
    }
}
