@import 'styles/scoped';

.main {
    top: 0;
    left: 0;
    right: 0;
    z-index: 501;
    position: fixed;
    background-color: $red;
    color: $white;
    @include spacing('padding-top', 's-16');
    @include spacing('padding-bottom', 's-16');
    @include spacing('padding-left', 's-32');
    @include spacing('padding-right', 's-32');
}

.list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        @include spacing('margin-right', 's-8');
    }
}

.btnLink {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    color: $white;
    border: none;
    border-radius: 0;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.isBtnClose {
    margin-right: 0;
    margin-left: auto;
}

.hasPipe:before {
    content: '|';
    display: inline-flex;
    opacity: 0.2;
    @include spacing('margin-right', 's-8');
}

.btnClose {
    display: inline-flex;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    border-radius: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    background-color: transparent;
    color: $white;

    @include mq(md) {
        top: 0;
        right: 0;
        position: absolute;
        @include spacing('padding', 's-16');
    }

    :global {
        .u-icon {
            width: get-vw(10px);
            @include spacing('margin-left', 's-8');

            path {
                fill: $white;
            }

            @include mq(md) {
                width: 10px;
                margin-left: 0;
            }
        }
    }
}

.btnCloseLabel {
    display: block;

    @include mq(md) {
        display: none;
    }
}

.blockMobile {
    @include mq(md) {
        width: 100%;
        display: block;
    }
}

.blockMobileOffset {
    @include mq(md) {
        @include spacing('margin-bottom', 's-4');
    }
}
