@import 'styles/scoped';

.languagePicker {
    @include spacing('padding-bottom', 's-120');

    width: 100%;
    background: $white;
}

.header {
    @include spacing('padding-top', 's-24');
    @include spacing('padding-bottom', 's-24');
    @include spacing('padding-left', 's-24');
    @include spacing('padding-right', 's-24');

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(md) {
        @include spacing('margin-bottom', 's-24');
    }
}

.logo {
    @include spacing('width', 's-56');
}

.close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    margin: 0;
    line-height: 1;
    background: none;
    cursor: pointer;
    transition: getTransition();
}

.title {
    @include spacing('margin-bottom', 's-56');

    @include mq(md) {
        @include spacing('margin-bottom', 's-64');

        text-align: center;
    }
}

.tabs {
    @include spacing('margin-bottom', 's-56');
}

.navWrapper {
    @include spacing('margin-bottom', 's-40');

    border-bottom: 2px solid $beige;

    @include mq(null, xl) {
        border-bottom-width: get-vw(2px);
    }
}

.nav {
    display: flex;
}

.navItem {
    flex: 1;
}

.navButton {
    @include spacing('padding', 's-8');

    display: block;
    width: 100%;
    outline: none;
    border: none;
    margin: 0 0 -2px 0;
    border-bottom: 2px solid transparent;
    background: none;
    cursor: pointer;
    transition: getTransition();

    @include mq(null, xl) {
        border-bottom-width: get-vw(2px);
        margin-bottom: get-vw(-2px);
    }

    &.isActive {
        border-bottom-color: $red;
        color: $red;
    }
}

.list {
    display: none;
    flex-wrap: wrap;

    &.isActive {
        display: flex;
    }
}

.listItem {
    @include spacing('margin-bottom', 's-32');

    width: percentage(1, 3);
    padding: 0 percentage(1, 18);

    @include mq(lg) {
        width: percentage(1.5, 3);
    }

    @include mq(md) {
        width: 100%;
        padding: 0;
    }
}

.languages {
    display: flex;

    li {
        @include spacing('margin-left', 's-8');

        white-space: nowrap;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &:after {
            @include spacing('margin-left', 's-8');

            content: '';
            width: 1px;
            height: 1em;
            position: relative;
            background: $faded;
            display: inline-block;
            vertical-align: middle;
        }
    }

    a {
        color: $faded;
        transition: getTransition();

        @include hover {
            color: $black;
        }
    }
}

.accordion {
    @include spacing('margin-bottom', 's-80');
}

.accordionSingle {
    border-bottom: 2px solid $beige;

    @include mq(null, xl) {
        border-bottom: get-vw(2px) solid $beige;
    }
}

.cta {
    @include spacing('padding', 's-56');

    background: $beige;
    border-radius: 6px;
    text-align: center;

    @include mq(null, xl) {
        border-radius: get-vw(6px);
    }
}

.ctaTitle {
    @include spacing('margin-bottom', 's-8');
}

// region Modal

.isOpened {
    :global {
        .ReactModal__Overlay--after-open {
            opacity: 1;
            visibility: visible;
        }

        .ReactModal__Overlay--before-close {
            opacity: 0;
            visibility: hidden;
        }

        .ReactModal__Content--after-open {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        .ReactModal__Content--before-close {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-25px);
        }
    }
}

.modalOverlay {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: getZindex(modal);
    position: fixed;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();
}

.modalMain {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    transform: translateY(-25px);
    transition: getTransition();
}

// endregion
