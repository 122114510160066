@import 'styles/scoped';

.accordionItem {
    border: 2px solid $beige;
    border-radius: 6px;
    transition: getTransition();

    @include mq(null, xl) {
        border-radius: get-vw(6px);
        border: get-vw(2px) solid $beige;
    }

    &:global {
        &.accordionItemColor__beige {
            border: 2px solid $beige;

            @include mq(null, xl) {
                border: get-vw(2px) solid $beige;
            }

            &.is-opened {
                background-color: $beige;
            }
        }

        &.accordionItemColor__white {
            border: 2px solid $white;

            @include mq(null, xl) {
                border: get-vw(2px) solid $white;
            }

            &.is-opened {
                background-color: $white;
            }
        }
    }
}

.accordionItemHeader {
    @include spacing('padding-top', 's-32');
    @include spacing('padding-bottom', 's-32');
    @include spacing('padding-left', 's-40');
    @include spacing('padding-right', 's-40');

    display: block;
    cursor: pointer;
    position: relative;
}

.accordionHeaderTitle {
    padding-right: 20px;
}

.accordionItemIndicator {
    @include spacing('right', 's-40');

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(8px);
    color: $red;
    transition: getTransition();

    @include mq(null, xl) {
        font-size: get-vw(8px);
    }
}

.accordionItemIndicatorActive {
    transform: translateY(-50%) scaleY(-1);
}

.accordionPanel {
    backface-visibility: hidden;
    overflow: hidden;
    height: 0;
}

.accordionPanelWrapper {
    @include spacing('padding-bottom', 's-40');
    @include spacing('padding-left', 's-40');
    @include spacing('padding-right', 's-40');

    color: rgba($black, 0.7);
}

.accordionGroup {
    width: 100%;
    display: block;
}

.accordionGroupItem {
    display: block;
    @include spacing('margin-bottom', 's-4');

    &:last-child {
        margin-bottom: 0;
    }
}

.accordionChildren {
    display: block;
    background-color: $beige;
    border-radius: get-vw(6px);
    @include spacing('padding-left', 's-32');
    @include spacing('padding-right', 's-32');
    @include spacing('padding-top', 's-16');
    @include spacing('padding-bottom', 's-16');

    &Header {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        @include spacing('padding-top', 's-4');
        @include spacing('padding-bottom', 's-4');

        & > li {
            display: block;
            @include spacing('padding-top', 's-4');
            @include spacing('padding-right', 's-8');
            @include spacing('padding-bottom', 's-4');

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &Title {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        align-content: center;
        -webkit-appearance: none;
        justify-content: flex-start;
        border: none;
        margin: 0;
        padding: 0;
        background-color: transparent;

        :global {
            .u-icon {
                fill: $red;
                transform: rotate(0deg);
                transition: getTransition();
                @include spacing('width', 's-8');
                @include spacing('margin-right', 's-8');
            }
        }

        &Active {
            :global {
                .u-icon {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &Content {
        height: 0;
        overflow: hidden;
    }
}
