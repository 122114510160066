@import 'styles/scoped';

.main {
    display: block;
    position: relative;
    text-align: center;

    @include hover {
        color: $red;

        .icon {
            transform: rotate(180deg);
        }

        .dropdownList {
            opacity: 1;
            visibility: visible;
            transform: translate(-33.33%, 0px);

            @include mq(lg) {
                transform: translate(0px, 0px);
            }
        }
    }
}

.labels {
    z-index: 2;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.label {
    display: block;
    border-left: 1px solid $beige;

    @include spacing('padding-left', 's-8');
    @include spacing('padding-right', 's-8');

    @include mq(null, xl) {
        border-left: get-vw(1px) solid $beige;
    }

    &:first-child,
    &:last-child {
        border-left: none;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        line-height: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.icon {
    color: $red;
    font-size: 60%;
    transform: rotate(0deg);
    transition: getTransition();
}

.dropdown {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: rem(-12px);

    @include mq(lg) {
        bottom: auto;
        position: relative;
        padding-top: rem(12px);
        display: none;
    }
}

.dropdownList {
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    width: rem(210px);
    overflow: hidden;
    position: absolute;
    border-radius: 6px;
    pointer-events: all;
    background-color: $white;
    transition: getTransition();
    transform: translate(-33.33%, 20px);
    box-shadow: 0 0 16px rgba($black, 0.08);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        width: get-vw(210px);
        border-radius: get-vw(6px);
    }

    @include mq(lg) {
        top: auto;
        width: 100%;
        max-width: rem(240px);
        opacity: 1;
        margin: 0 auto;
        display: block;
        overflow: unset;
        position: relative;
        visibility: visible;
        transform: translate(0px, 0px);
    }
}

.dropdownItem {
    display: block;
    text-align: center;
    border-bottom: 1px solid $maize;

    @include mq(null, xl) {
        border-bottom: get-vw(1px) solid $maize;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.link {
    width: 100%;
    margin: 0;
    display: block;
    color: $black;
    border-radius: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;

    @include spacing('padding', 's-16');

    @include hover {
        color: $red;
    }
}

.dropdownLabel {
    display: block;
    color: $black;
    background-color: $beige;

    @include spacing('padding', 's-16');
}

.mobileActive {
    @include mq(md) {
        color: $red;
    }

    .icon {
        @include mq(md) {
            transform: rotate(180deg);
        }
    }

    .dropdown {
        @include mq(lg) {
            display: block;
        }
    }
}
