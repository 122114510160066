@import 'styles/scoped';

.wrapper {
    @include spacing('padding', 's-8');

    display: block;
    top: 0;
    right: 0;
    left: 0;
    z-index: getZindex(navigation);
    position: fixed;
    pointer-events: none;

    @include mq(lg) {
        display: flex;
        flex-wrap: nowrap;
        transition: getTransition();
        flex-direction: column-reverse;
    }
}

.underlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $black-50;
    z-index: getZindex(negative);
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();

    &.isActive {
        opacity: 1;
        visibility: visible;
    }
}

.logo {
    width: rem(52px);
    display: block;

    @include mq(null, xl) {
        width: get-vw(52px);
    }

    @include mq(lg) {
        display: none;
    }

    img {
        width: 100%;
        display: block;
    }
}

.nav {
    @include spacing('padding-top', 's-16');
    @include spacing('padding-bottom', 's-16');
    @include spacing('padding-left', 's-24');
    @include spacing('padding-right', 's-24');

    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $beige;
    pointer-events: all;

    @include mq(null, xl) {
        border-radius: get-vw(6px);
        border: get-vw(1px) solid $beige;
    }

    @include mq(lg) {
        padding: 0;
        opacity: 0;
        visibility: hidden;
        height: auto;
        border-radius: 0;
        flex-direction: column;
        flex: 1 1 auto;
        border: none;
        //overflow: auto;
        pointer-events: none;
        transition: getTransition();
        -webkit-overflow-scrolling: touch;
    }
}

.navList {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    @include mq(lg) {
        width: 100%;
        display: block;
    }
}

.navListItem {
    @include spacing('margin-right', 's-32');

    text-align: center;

    @include mq(lg) {
        margin-right: 0;
        text-align: left;
        border-bottom: 2px solid $beige;
    }

    a {
        color: $black;
        cursor: pointer;

        @include mq(lg) {
            display: block;
            padding: rem(16px) rem(24px);
        }

        @include hover {
            color: $red;
        }
    }
}

.navListItemMobileElement {
    display: block;
    position: relative;
}

.navListItemMobileBtn {
    width: rem(40px);
    top: 0;
    right: 0;
    height: 100%;
    display: none;
    z-index: 2;
    flex-wrap: nowrap;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    background-color: transparent;

    @include mq(lg) {
        display: flex;
    }
}

.navListItemDropdownIcon {
    @include spacing('margin-left', 's-8');

    font-size: 60%;
    color: $red;

    @include mq(lg) {
        display: none !important;
    }
}

.navListItemArrowIcon {
    font-size: 60%;
}

.submenu {
    @include spacing('padding', 's-56');
    @include spacing('margin-top', 's-4');

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
    position: absolute;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $beige;
    box-shadow: 0 0 16px rgba($black, 0.08);
    transition: getTransition();
    transform: translate(0%, 50px);

    @include mq(null, xl) {
        border-radius: get-vw(6px);
        border: get-vw(1px) solid $beige;
    }

    @include mq(lg) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        border: none;
        margin-top: 0;
        z-index: getZindex(submenu);
        overflow: auto;
        padding: 0;
        display: block;
        transform: translate(100%, 0px);
        -webkit-overflow-scrolling: touch;
    }
}

.submenuActive {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translate(0%, 0px);

    @include mq(lg) {
        transform: translate(0%, 0%);
    }
}

.submenuLeft {
    @include spacing('margin-right', 's-40');

    display: block;
    flex: 0 0 rem(150px);
    position: relative;
    z-index: 2;

    @include mq(null, xl) {
        flex: 0 0 get-vw(150px);
    }

    @include mq(lg) {
        width: 100%;
        padding-right: 0;
        text-align: center;
    }
}

.submenuNav {
    display: block;
    list-style-type: none;

    @include mq(lg) {
        padding-top: 4px;
    }

    li {
        @include spacing('padding-bottom', 's-8');

        display: block;

        @include mq(lg) {
            text-align: left;
            padding-bottom: 0;
        }
    }

    a {
        @include mq(lg) {
            padding: 12px 48px;
        }
    }

    & + .submenuNav {
        @include spacing('padding-top', 's-40');

        @include mq(lg) {
            padding-top: 0;
        }
    }
}

.submenuNavRed a {
    color: $red;

    @include hover {
        color: $blue;
    }
}

.submenuRight {
    display: block;
    flex: 1 1 auto;
    position: relative;
    z-index: 1;

    @include mq(lg) {
        @include spacing('padding-top', 's-40');

        padding-left: rem(20px);
        padding-right: rem(20px);
    }
}

.submenuMobileBtnBack {
    width: 100%;
    display: none;
    flex-wrap: nowrap;
    cursor: pointer;
    margin: 0;
    border: none;
    padding: rem(16px) rem(24px);
    border-radius: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    background-color: transparent;
    border-bottom: 2px solid $beige;

    @include mq(null, xl) {
        border-bottom: get-vw(2px) solid $beige;
    }

    span {
        margin-left: rem(16px);
    }

    @include mq(lg) {
        display: flex;
    }
}

.contentFlex {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    @include mq(lg) {
        display: block;
    }
}

.contentProducts {
    display: block;
}

.contentProductsCol {
    @include spacing('padding-bottom', 's-16');
    @include spacing('padding-left', 's-12');
    @include spacing('padding-right', 's-12');

    width: 25%;
    display: block;
    text-align: center;
    border-left: 1px solid $beige;

    @include mq(null, xl) {
        border-left: get-vw(1px) solid $beige;
    }

    @include mq(lg) {
        @include spacing('padding-bottom', 's-56');

        width: 100%;
        border: none;
        padding-right: 0;
        padding-left: 0;
    }

    img {
        @include spacing('margin-bottom', 's-24');

        width: 100%;
        display: block;
    }

    a {
        @include mq(lg) {
            padding: 0;
        }
    }
}

.contentAthlete {
    display: block;
    margin-left: get-vw(-32px);

    @include mq(lg) {
        margin-left: 0;
    }
}

.contentAthleteCol {
    display: block;
    width: 20%;
    padding-left: get-vw(32px);

    @include mq(lg) {
        @include spacing('padding-bottom', 's-56');

        width: 100%;
        padding-left: 0;
    }

    a {
        @include mq(lg) {
            padding: 0;
        }
    }
}

.contentMagazine {
    display: block;
    margin-left: get-vw(-56px);

    @include mq(lg) {
        margin-left: 0;
    }
}

.contentMagazineCol {
    display: block;
    width: 33.33%;
    padding-left: get-vw(56px);

    @include mq(lg) {
        @include spacing('padding-bottom', 's-56');

        width: 100%;
        padding-left: 0;
    }

    a {
        @include mq(lg) {
            padding: 0;
        }
    }
}

.navRight {
    display: block;

    @include mq(lg) {
        width: 100%;
        padding: rem(20px);
    }
}

.navSideMenu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    text-align: center;

    @include mq(lg) {
        display: block;
    }
}

.navSideMenuItem {
    @include spacing('margin-right', 's-32');

    display: block;

    @include mq(lg) {
        margin-right: 0;
        padding: rem(10px) 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.navSideMenuItemInherit {
    a {
        color: $black;

        @include hover {
            color: $red;
        }
    }
}

.navSideMenuItemMobileFalse {
    @include mq(lg) {
        display: none !important;
    }
}

// region Mobile

.navMobile {
    width: 100%;
    display: none;
    padding: rem(12px) rem(16px);
    margin-bottom: 2px;
    background-color: $white;
    pointer-events: all;
    flex: 0 0 auto;
    table-layout: fixed;
    border: 1px solid $beige;

    @include mq(null, xl) {
        border: get-vw(1px) solid $beige;
    }

    @include mq(lg) {
        display: table;
    }
}

.navMobileCol {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }
}

.navMobileLogo {
    width: 45px;
    display: block;
    margin: 0 auto;
}

.navMobileColRight {
    display: inline-block;
}

.hamburger {
    margin: 0;
    padding: 10px 0;
    width: rem(20px);
    display: block;
    position: relative;
    border-radius: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;

    span {
        width: 100%;
        display: block;
        height: 1px;
        background-color: $black;
        opacity: 1;
        visibility: visible;
        transition: getTransition();
    }

    &:before,
    &:after {
        left: 0;
        right: 0;
        height: 1px;
        content: '\A';
        position: absolute;
        background-color: $black;
        transform: rotate(0deg);
        transition: getTransition();
    }

    &:before {
        top: 4px;
    }

    &:after {
        bottom: 4px;
    }
}

.isMobileActive {
    @include mq(lg) {
        height: 100vh;
        background-color: $beige;
    }

    .nav {
        @include mq(lg) {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    .navMobile {
        border: 1px solid $white;

        @include mq(null, xl) {
            border: get-vw(1px) solid $white;
        }
    }

    .hamburger {
        span {
            opacity: 0;
            visibility: hidden;
        }

        &:before {
            top: 10px;
            transform: rotate(-45deg);
        }

        &:after {
            bottom: 10px;
            transform: rotate(45deg);
        }
    }
}

.isMobileSubmenuActive {
    .nav {
        @include mq(lg) {
            overflow: hidden;
        }
    }

    .submenu {
        @include mq(lg) {
            transform: translateX(0%);
        }
    }
}

// endregion
