@import 'styles/scoped';

.link {
    color: $blue !important;
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    @include hover {
        color: $red !important;
    }
}

.linkIcon {
    font-size: 50%;

    @include spacing('margin-left', 's-8');
}
