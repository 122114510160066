@import 'styles/scoped';

.o-section {
    position: relative;

    &--red {
        background: $red;
    }

    &--beige {
        background-color: $beige;
    }

    &--white {
        background-color: $white;
    }

    &--overflow {
        overflow: hidden;
    }

    &--mobile-overflow {
        @include mq(md) {
            overflow: hidden;
        }
    }
}
