@import '../../styles/tools/tools.index';

.u-reveal-element {
    opacity: 0;
    backface-visibility: hidden;
    will-change: opacity;
    //display: inline-block;
    position: relative;
    display: block;
    transform: translateY(50px);
}
