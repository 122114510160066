.u-a7 {
    font-family: $font-secondary;
    $font-size: (
        $breakpoint-sm: 30px,
        $breakpoint-xl: 75px,
        $breakpoint-xxl: 225px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 32px,
        $breakpoint-xl: 75px,
        $breakpoint-xxl: 225px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.01em;
}

.u-a6 {
    font-family: $font-secondary;
    $font-size: (
        $breakpoint-sm: 25px,
        $breakpoint-xl: 60px,
        $breakpoint-xxl: 180px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 28px,
        $breakpoint-xl: 64px,
        $breakpoint-xxl: 192px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.01em;
}

.u-a5 {
    font-family: $font-secondary;
    $font-size: (
        $breakpoint-sm: 20px,
        $breakpoint-xl: 45px,
        $breakpoint-xxl: 135px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 23px,
        $breakpoint-xl: 50px,
        $breakpoint-xxl: 150px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.02em;
}

.u-a4 {
    font-family: $font-secondary;
    $font-size: (
        $breakpoint-sm: 18px,
        $breakpoint-xl: 28px,
        $breakpoint-xxl: 84px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 23px,
        $breakpoint-xl: 32px,
        $breakpoint-xxl: 96px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    @include mq(sm) {
        letter-spacing: -0.02em;
    }
}

.u-a3 {
    font-family: $font-secondary;
    $font-size: (
        $breakpoint-sm: 16px,
        $breakpoint-xl: 18px,
        $breakpoint-xxl: 54px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 20px,
        $breakpoint-xl: 24px,
        $breakpoint-xxl: 72px,
    );

    @include poly-fluid-sizing('line-height', $line-height);
}

.u-a2 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 16px,
        $breakpoint-xl: 18px,
        $breakpoint-xxl: 54px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 21px,
        $breakpoint-xl: 28px,
        $breakpoint-xxl: 84px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.02em;

    @include mq(sm) {
        letter-spacing: -0.01em;
    }
}

.u-a1 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 16px,
        $breakpoint-xl: 18px,
        $breakpoint-xxl: 54px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 18px,
        $breakpoint-xl: 20px,
        $breakpoint-xxl: 60px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.02em;
}

.u-b0 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 14px,
        $breakpoint-xl: 16px,
        $breakpoint-xxl: 48px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 20px,
        $breakpoint-xl: 25px,
        $breakpoint-xxl: 75px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.02em;

    @include mq(sm) {
        letter-spacing: -0.01em;
    }
}

.u-b1 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 14px,
        $breakpoint-xl: 14px,
        $breakpoint-xxl: 42px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 18px,
        $breakpoint-xl: 18px,
        $breakpoint-xxl: 54px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: -0.02em;
}

.u-b2 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 12px,
        $breakpoint-xl: 12px,
        $breakpoint-xxl: 36px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 15px,
        $breakpoint-xl: 15px,
        $breakpoint-xxl: 45px,
    );

    @include poly-fluid-sizing('line-height', $line-height);

    letter-spacing: 0.1em;
}

.u-b3 {
    font-family: $font-primary;
    $font-size: (
        $breakpoint-sm: 12px,
        $breakpoint-xl: 12px,
        $breakpoint-xxl: 36px,
    );

    @include poly-fluid-sizing('font-size', $font-size);

    $line-height: (
        $breakpoint-sm: 14px,
        $breakpoint-xl: 14px,
        $breakpoint-xxl: 42px,
    );

    @include poly-fluid-sizing('line-height', $line-height);
}

.u-uppercase {
    text-transform: uppercase;
}

.u-capitalize {
    text-transform: capitalize;
}

.u-text-left {
    text-align: left;
}

.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-underline {
    text-decoration: underline;
}

.u-fw-regular,
.u-fw-normal,
.u-fw-400 {
    font-weight: 400;
}

.u-fw-medium,
.u-fw-500 {
    font-weight: 500;
}

.u-fw-bold,
.u-fw-700 {
    font-weight: 700;
}
