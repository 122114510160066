@import 'styles/scoped';

.button {
    @include spacing('height', 's-64');
    @include spacing('padding-left', 's-56');
    @include spacing('padding-right', 's-56');

    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $red;
    text-decoration: none;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    outline: 0;
    border: 2px solid $red;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 60px;
    transition: getCustomTransition(all 0.3s);
    color: $white;
    backface-visibility: hidden;

    @include hover {
        &:not(:disabled) {
            transition: getCustomTransition(all 0.5s);
            background: $maize;
            border-color: $maize;
            color: $black;
        }
    }

    @include mq(null, xl) {
        height: get-vw(64px);
        border: get-vw(2px) solid $red;
        border-radius: get-vw(60px);
    }

    @include mq(md) {
        @include spacing('height', 's-80');
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    > div {
        @include spacing('margin-left', 's-8');
    }
}

.transparent {
    background: transparent;
    color: $red;

    @include hover {
        background: transparent;
        border-color: $maize;
        color: $maize;
    }
}

.white {
    background: $white;
    border-color: $white;
    color: $red;

    &.transparent {
        background: transparent;
        color: $white;

        @include hover {
            background: transparent;
            border-color: $maize;
            color: $maize;
        }
    }
}

.icon {
    @include spacing('margin-left', 's-8');
}

.fullWidth {
    width: 100%;
}

.small {
    @include spacing('height', 's-48');

    @include mq(md) {
        @include spacing('height', 's-64');
    }
}

.widthXsmall {
    @include spacing('padding-left', 's-24');
    @include spacing('padding-right', 's-24');

    @include mq(lg) {
        @include spacing('padding-left', 's-56');
        @include spacing('padding-right', 's-56');
    }
}

.heightXsmall {
    letter-spacing: 0;
    @include spacing('height', 's-32');

    @include mq(lg) {
        @include spacing('height', 's-64');
    }
}
