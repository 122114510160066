html,
body {
    -webkit-font-smoothing: antialiased;
}

html {
    scroll-behavior: smooth;
}

body {
    @include font-default;
    @include default-font-size;

    color: $black;
    background-color: $white;
}

// removes ios zoom for all elements
* {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}
