@import 'styles/scoped';

.transition {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    background-color: $beige;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &--in {
        .inner {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }
    }

    &--out {
        .inner {
            opacity: 0;
            visibility: hidden;
            transform: scale(0.4);
        }
    }
}

.inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    transition: getTransition('slide');
    opacity: 0;
    visibility: hidden;
    transform: scale(0.4);

    svg {
        display: block;
        width: get-vw(160px);
        height: get-vw(160px);
        transform-origin: center;
        animation: rotate-center 1.4s linear infinite both;
        -webkit-animation: rotate-center 1.4s linear infinite both;

        @include mq(sm) {
            width: 80px;
            height: 80px;
        }
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
