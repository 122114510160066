@import 'styles/scoped';

.figure {
    @include spacing('margin-bottom', 's-32');
}

.figureDesktop {
    display: block;

    @include mq(sm-md) {
        display: none;
    }
}

.figureMobile {
    display: none;

    @include mq(sm-md) {
        display: block;
    }
}

.athleteTitle {
    @include spacing('margin-bottom', 's-8');

    display: block;
}

.subtitle {
    color: $faded;
    display: block;
    font-weight: normal;
    text-transform: uppercase;

    & + .athleteSport {
        @include spacing('margin-top', 's-8');
    }
}

.athleteSport {
    color: $faded;
    display: block;
    font-weight: normal;
    text-transform: uppercase;
}

.country {
    @include spacing('margin-top', 's-4');
}

.center {
    text-align: center;
}
