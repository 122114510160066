@use 'sass:math';

@import 'styles/scoped';

.article {
    width: 100%;
    display: block;
}

.figure {
    width: 100%;
    display: block;
    position: relative;
    height: 0;
    padding-bottom: percentage(300, 520);
    overflow: hidden;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include spacing('margin-bottom', 's-24');

    @include mq(null, xl) {
        border-radius: get-vw(10px);
    }

    a,
    figure {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 10px;

        @include mq(null, xl) {
            border-radius: get-vw(10px);
        }
    }

    img {
        display: none;
    }

    figure {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: getTransition('zoom');
        -webkit-backface-visibility: hidden; /* add to fix webkit bug jitter */
        -webkit-transform: perspective(1000px); /* add to fix webkit bug jitter */
    }

    @include hover {
        figure {
            transform: scale(1.1);
        }
    }
}

.figureHovered figure {
    transform: scale(1.05);
}

.eyebrow {
    display: block;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    color: $red;

    @include spacing('margin-bottom', 's-8');
}

.title {
    @include spacing('margin-bottom', 's-8');

    max-width: rem(480px);
    display: block;

    @include mq(null, xl) {
        max-width: get-vw(480px);
    }

    a {
        display: block;
    }

    &Small {
        font-weight: normal;
    }

    &Big {
        font-weight: bold;
    }
}

.date {
    display: block;
    color: $faded;
    max-width: rem(480px);

    @include mq(null, xl) {
        max-width: get-vw(480px);
    }
}
