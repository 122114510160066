.u-content-editor {
    word-break: break-word;

    hr {
        @include spacing('margin-bottom', 's-64');

        display: block;
        width: 100%;
        height: 2px;
        border-radius: 0;
        border: none;
        padding: 0;
        background-color: $beige;

        @include mq(null, xl) {
            height: get-vw(2px);
        }
    }

    h2,
    h3,
    h4 {
        display: block;
    }

    h2 {
        @extend .u-a4;

        @include spacing('padding-bottom', 's-16');

        font-weight: 700;
    }

    h3 {
        @extend .u-a3;

        @include spacing('padding-bottom', 's-16');

        font-weight: 700;
    }

    h4 {
        @extend .u-b1;

        @include spacing('padding-bottom', 's-16');

        font-weight: 500;
    }

    p {
        @extend .u-a2;

        @include spacing('padding-bottom', 's-32');

        & + h2 {
            @include spacing('padding-top', 's-40');
        }

        & + h3 {
            @include spacing('padding-top', 's-8');
        }

        & + hr {
            @include spacing('margin-top', 's-32');
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    a {
        color: $red;
    }

    ul,
    ol {
        @include spacing('padding-bottom', 's-32');

        padding-left: get-vw(60px);

        &:last-child {
            padding-bottom: 0;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        @extend .u-a2;

        @include spacing('padding-bottom', 's-16');

        &:last-child {
            padding-bottom: 0;
        }
    }

    //default gutenberg blocks overrides
    //images
    .wp-block-image {
        img {
        }

        //wide image
        &.alignwide {
            //overrides
            figcaption {
            }
        }

        //full width image
        &.alignfull {
            //overrides
            figcaption {
            }
        }
    }

    //quote
    .wp-block-quote {
        &.is-style-large {
            p,
            cite {
            }

            p {
            }

            cite {
            }
        }

        //overrides
        p {
        }

        cite {
        }
    }

    //images
    .wp-block-video {
        video {
        }

        //wide image
        &.alignwide {
            //overrides
            figcaption {
            }
        }

        //full width image
        &.alignfull {
            //overrides
            figcaption {
            }
        }
    }

    // modifiers
    &--module {
        p {
            @extend .u-b0;

            @include spacing('padding-bottom', 's-24');
        }

        li {
            @extend .u-b0;
        }
    }
}
