@use 'sass:math';

@import 'styles/scoped';

.parallaxOvalImage {
    position: relative;
    width: 100%;
}

.shape {
    height: 0;
    border-radius: 320px;
    width: 100%;
    padding-bottom: percentage(740, 420);
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include mq(null, xl) {
        border-radius: get-vw(320px);
    }

    @include mq(sm-md) {
        padding-bottom: percentage(186, 328);
    }

    &.parallaxDisabled {
        @include hover {
            .parallaxBackground {
                transform: scale(1.05);
            }
        }
    }
}

.parallaxBackground {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 110%;
    top: -10%;
    left: 0;

    .parallaxDisabled & {
        transition: getTransition('zoom');
        height: 100%;
        top: 0;
    }
}

.hasBorderRadius .shape {
    border-radius: 20px;

    @include mq(null, xl) {
        border-radius: get-vw(20px);
    }
}
