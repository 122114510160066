@import 'styles/scoped';

.initial {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    position: fixed;
    color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: $black-40;
    @include spacing('padding', 's-32');
    opacity: 0;
    visibility: hidden;
    transition: getTransition();
    z-index: getZindex(modal, -1);

    &--active {
        opacity: 1;
        visibility: visible;
    }
}

.initialBox {
    width: 100%;
    display: block;
    max-width: 384px;
    background-color: $white;
    border-radius: get-vw(6px);
    @include spacing('padding', 's-32');

    @include mq(sm-md) {
        @include spacing('padding', 's-48');
    }

    @include mq(null, xl) {
        max-width: get-vw(384px);
    }
}

.initialTitle {
    display: block;
    @include spacing('padding-bottom', 's-8');
}

.initialText {
    display: block;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.initialButtons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    li {
        @include spacing('margin-top', 's-16');
        @include spacing('margin-right', 's-24');

        &:last-child {
            margin-right: 0;
        }
    }
}

.modalOverlay {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: getZindex(modal);
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();
    background-color: $black-40;

    &--opened {
        opacity: 1;
        visibility: visible;
    }

    &--close {
        opacity: 0;
        visibility: hidden;
    }
}

.modalMain {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25px);
    transition: getTransition();
    pointer-events: none;
    padding: percentage(1, 24);

    &--opened {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &--close {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-25px);
    }
}

.container {
    @include spacing('padding', 's-64');

    width: 100%;
    display: block;
    max-width: get-vw(720px);
    pointer-events: all;
    background-color: $white;
    border-radius: get-vw(10px);
    text-align: left;
    position: relative;

    @include mq(lg) {
        max-width: get-vw(900px);
    }

    @include mq(md) {
        max-width: 100%;
    }
}

.btnClose {
    color: $black;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    background-color: transparent;
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 0;
    @include spacing('padding', 's-24');

    :global {
        .u-icon {
            transform: rotate(0deg);
            will-change: transform;
            transition: getTransition();
        }
    }

    &:hover :global {
        .u-icon {
            transform: rotate(-90deg);
        }
    }
}

.btnIcon {
    width: get-vw(15px);
    height: get-vw(15px);

    @include mq(md) {
        width: 15px;
        height: 15px;
    }
}

.modalTitle {
    display: block;
    @include spacing('padding-bottom', 's-16');
}

.truncatedText {
    display: block;
    @include spacing('padding-bottom', 's-8');
}

.truncateBtn {
    color: $red;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background-color: transparent;

    &:hover {
        text-decoration: none;
    }
}

.accordions {
    display: block;
    @include spacing('padding-top', 's-24');
}

.accordionsItem {
    display: block;
    @include spacing('padding-bottom', 's-4');

    &:last-child {
        padding-bottom: 0;
    }
}

.accordionsContent {
    @include spacing('padding-top', 's-8');
    @include spacing('padding-bottom', 's-8');
}

.table {
    overflow: auto;
    display: block;
    @include spacing('padding-top', 's-16');

    table {
        width: 100%;
        display: table;
        border-spacing: 0;
        text-align: left;
        border-collapse: collapse;

        thead {
            text-align: left;
            font-weight: normal;

            th {
                border: none;
                font-weight: normal;
                background-color: $maize;
                @include spacing('padding', 's-16');

                &:first-child {
                    border-radius: get-vw(6px) 0 0 0;
                }

                &:last-child {
                    border-radius: 0 get-vw(6px) 0 0;
                }
            }
        }

        tbody {
            font-weight: normal;

            tr {
                background-color: $white;
                border-top: 2px solid $beige;
            }

            td {
                @include spacing('padding', 's-16');
            }
        }
    }
}

.btnToggle {
    cursor: pointer;
    border-radius: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    &Radio {
        transition: getTransition();
        border: 1px solid $maize;
        background-color: $white;
        border-radius: get-vw(20px);
        @include spacing('padding', 's-4');
        @include spacing('margin-left', 's-8');

        &:before {
            left: 0;
            content: '\A';
            display: block;
            border-radius: 100%;
            position: relative;
            pointer-events: none;
            background-color: $maize;
            transform: translateX(0px);
            transition: getTransition();
            @include spacing('width', 's-16');
            @include spacing('height', 's-16');
            @include spacing('margin-right', 's-24');
        }

        &Active {
            border: 1px solid $red;

            &:before {
                background-color: $red;
                transform: translateX(100%);
                @include spacing('left', 's-8');
            }
        }
    }
}

.btnSave {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    @include spacing('padding-top', 's-24');
}
