@import '../tools/_tools.index';
@import '../settings/_settings.colors';
@import '../settings/_settings.transitions';
@import '../settings/_settings.zindex';
@import '../settings/_settings.breakpoints';
@import '../settings/_settings.spacings';

/**
 * Form
 */
$form-transition: getTransition();

/*
 * Fieldset
 */
$form-fieldset-padding: 0;

/*
 * Label and legend
 */
$form-label-color: $black;

/*
 * Input
 * 1. Hover
 * 2. Focus
 * 3. Disabled
 * 4. Placeholder
 * 5. Placeholder disabled
 */
$form-input-width: 100%;
$form-input-margin: 0;
$form-input-border-style: solid;
$form-input-border-width: 1px 1px 1px 1px;
$form-input-border-width-ws: get-vw(1px) get-vw(1px) get-vw(1px) get-vw(1px);
$form-input-border-color: $white;
$form-input-border-color-dark: $maize;
$form-input-border-error-color: $red;
$form-input-border-radius: 6px;
$form-input-background-color: $white;
$form-input-box-shadow: none;
$form-input-color: $black;

/* 1 */
$form-input-hover-border-color: false;
$form-input-hover-background-color: false;
$form-input-hover-color: false;

/* 2 */
$form-input-focus-border-color: false;
$form-input-focus-background-color: false;
$form-input-focus-color: false;

/* 3 */
$form-input-disabled-border-color: false;
$form-input-disabled-background-color: rgba($white, 0.6);

$form-input-disabled-color: $light-gray;

/* 4 */
$form-input-placeholder-color: rgba($black, 0.6);

/* 5 */
$form-input-disabled-placeholder-color: $light-gray;

/*
 * Textarea
 */
$form-textarea-min-height: auto;

/*
 * Checkbox
 * 1. Spacing of single checkbox wrapper
 * 2. Color of checked icon that will be injected in the SVG fill, hex only
 * 3. Slice # from hex
 * 4. SVG code without double marks, color without hex is injected in SVG fill where %23 is UTF8 "#"
 * 5. Left space of label from the fake checkbox ($form-checkbox-width + $form-checkbox-label-left-position)
 * 6. Fine tune the top space of both holder and checked indicator, because of line height on label
 * 7. Hover
 * 8. Focus
 * 9. Checked
 * 10. Disabled
 * 11. IE11 support with base64 SVG - needs two
 */
$form-checkbox-border: 1px solid $maize;
$form-checkbox-border-ws: get-vw(1px) solid $maize;
$form-checkbox-border-radius: 3px;
$form-checkbox-background-color: $white;

/* 2 */
$form-checkbox-icon-color: $black;

/* 6 */
$form-checkbox-top-position: 1px;

/* 7 */
$form-checkbox-hover-border: false;
$form-checkbox-hover-background-color: false;

/* 8 */
$form-checkbox-focus-border: false;
$form-checkbox-focus-background-color: false;

/* 9 */
$form-checkbox-checked-border: false;
$form-checkbox-checked-background-color: false;

/* 10 */
$form-checkbox-disabled-label-color: rgba($white, 0.6);
$form-checkbox-disabled-border: false;
$form-checkbox-disabled-background-color: false;
$form-checkbox-disabled-icon-color: rgba($white, 0.6);

/*
 * Radio
 * 1. Spacing of single radio wrapper
 * 1. Color of checked icon
 * 2. Left space of label from the fake checkbox ($form-radio-width + $form-radio-label-left-position)
 * 3. Fine tune the top space of both holder and checked indicator, because of line height on label
 * 6. Hover
 * 7. Focus
 * 8. Checked
 * 9. Disabled
 * 10. IE11 support with base64 SVG - needs two
 */
$form-radio-border: 1px solid $black;
$form-radio-border-ws: get-vw(1px) solid $black;
$form-radio-border-radius: 100%;
$form-radio-background-color: transparent;
/* 2 */
$form-radio-icon-color: $black;
$form-radio-icon-border-radius: 100%;
/* 4 */
$form-radio-top-position: 0.4em;
/* 5 */
$form-radio-hover-border: false;
$form-radio-hover-background-color: false;
/* 6 */
$form-radio-focus-border: false;
$form-radio-focus-background-color: false;
/* 7 */
$form-radio-checked-border: 1px solid #000000;
$form-radio-checked-border-ws: 1px solid #000000;
$form-radio-checked-background-color: false;
/* 8 */
$form-radio-disabled-label-color: rgba(#000000, 0.2);
$form-radio-disabled-border: 1px solid #d9d9d9;
$form-radio-disabled-border-ws: get-vw(1px) solid #d9d9d9;
$form-radio-disabled-background-color: false;
$form-radio-disabled-icon-color: #d9d9d9;

.u-form {
    display: block;
    width: 100%;

    /**
		 * Light modifier
		 */

    &--dark {
        .u-form__input {
            border-color: $form-input-border-color-dark;
        }
    }

    /**
         * Fieldset
         * usage: .u-form__fieldset
         */
    &__fieldset {
        padding: $form-fieldset-padding;
        margin: 0;

        @include spacing('margin-bottom', 's-32');

        border-width: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mq(sm-md) {
            display: block;
        }
    }

    &__col {
        @include spacing('margin-right', 's-8');

        flex: 1;

        &:last-child {
            margin-right: 0;

            @include mq(sm-md) {
                margin-bottom: 0;
            }
        }

        @include mq(sm-md) {
            @include spacing('margin-bottom', 's-8');

            margin-right: 0;
        }
    }

    /**
         * Label and legend
         * usage: .u-form__label
         * usage: .u-form__legend
         */
    &__label,
    &__legend {
        display: block;
        margin: 0;

        @include spacing('margin-bottom', 's-8');

        color: $form-label-color;
        cursor: pointer;

        &--flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__input-wrapper {
        display: block;
        position: relative;

        .u-form__input {
            z-index: 1;
            position: relative;
        }

        .u-form__tooltip {
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include spacing('margin-top', 's-4');
            @include spacing('right', 's-8');
        }
    }

    &__error-msg {
        @include spacing('margin-top', 's-8');

        color: $red;
    }

    /**
         * Input resets and styling
         * 1. Reset display and dimensions for all browsers.
         * 2. Reset and define spacing for all browsers
         * 3. Reset background and border for all browsers
         * 4. Make font properties consistent in IE and Safari with other browsers
         * 5. Add transitions on input
         * 6. Remove platform specific default styling for inputs like search
         * 7. Hover state
         * 8. Focus state - disable outline and enable border for better design
         * 9. Disabled state
         * usage: .u-form__input
         */
    &__input {
        /* 1 */
        display: block;
        width: $form-input-width;

        @include spacing('height', 's-64');

        /* 2 */
        @include spacing('padding-left', 's-24');
        @include spacing('padding-right', 's-24');

        padding-top: 0;
        padding-bottom: 0;
        margin: $form-input-margin;

        /* 3 */
        border-style: $form-input-border-style;
        border-width: $form-input-border-width;
        border-color: $form-input-border-color;
        border-radius: $form-input-border-radius;
        background-color: $form-input-background-color;
        box-shadow: $form-input-box-shadow;
        outline: none;

        /* 4 */
        color: $form-input-color;

        @extend .u-b0;

        /* 5 */
        transition: $form-transition;

        /* 6 */
        -webkit-appearance: none;
        -moz-appearance: none;

        @include mq(null, xl) {
            border-radius: get-vw($form-input-border-radius);
            border-width: $form-input-border-width-ws;
        }

        @include mq(md) {
            @include spacing('height', 's-80');
        }

        &.has-error {
            border-color: $form-input-border-error-color;
        }

        /* 7 */
        &:hover {
            outline: 0;
            @if $form-input-hover-border-color != false {
                border-color: $form-input-hover-border-color;
            }
            @if $form-input-hover-background-color != false {
                background-color: $form-input-hover-background-color;
            }
            @if $form-input-hover-color != false {
                color: $form-input-hover-color;
            }
        }

        /* 8 */
        &:focus {
            outline: 0;
            @if $form-input-focus-border-color != false {
                border-color: $form-input-focus-border-color;
            }
            @if $form-input-focus-background-color != false {
                background-color: $form-input-focus-background-color;
            }
            @if $form-input-focus-color != false {
                color: $form-input-focus-color;
            }
        }

        /* 9 */
        &:disabled {
            outline: 0;
            @if $form-input-disabled-border-color != false {
                border-color: $form-input-disabled-border-color;
            }
            @if $form-input-disabled-background-color != false {
                background-color: $form-input-disabled-background-color;
            }
            @if $form-input-disabled-color != false {
                color: $form-input-disabled-color;
            }
        }

        /**
             * Input placeholders
             * 1. Chrome, Firefox, Opera, Safari 10.1+
             * 2. Firefox needs opacity reset
             * 3. Internet Explorer 10-11
             * 4. Microsoft Edge
             * 5. Disabled placeholder color
             */

        /* 1 */
        &::placeholder {
            color: $form-input-placeholder-color;

            /* 2 */
            opacity: 1;
        }

        /* 3 */
        &:-ms-input-placeholder {
            color: $form-input-placeholder-color;
        }

        /* 4 */
        &::-ms-input-placeholder {
            color: $form-input-placeholder-color;
        }

        /* 5 */
        &:disabled {
            &::placeholder {
                color: $form-input-disabled-placeholder-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $form-input-disabled-placeholder-color;
            }

            &::-ms-input-placeholder {
                color: $form-input-disabled-placeholder-color;
            }
        }
    }

    /**
         * Remove resizing option from textarea
         * Add height or min height to textarea
         * Needs to be combined with .u-form__input for reset
         * usage: .u-form__input .u-form__input--textarea
         */
    &__input--textarea {
        @include spacing('padding-top', 's-24');
        @include spacing('padding-bottom', 's-24');
        @include spacing('height', 's-200');

        resize: none;
        min-height: $form-textarea-min-height;
    }

    /**
         * Checkbox and radio common style
         * 1. Hide default HTML input
         * 2. Single checkbox wrapper
         * 3. Single radio wrapper
         * 4. Reset line height on fake checkbox and radio
         */

    /* 1 */
    &__checkbox,
    &__radio {
        position: absolute;
        z-index: getZindex(negative);
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* 2 */
    &__checkbox-wrapper {
        @include spacing('margin-bottom', 's-12');

        &:last-child {
            margin-bottom: 0;
        }
    }

    /* 4 */
    &__checkbox-label,
    &__radio-label {
        &:before,
        &:after {
            line-height: 1;
        }

        a {
            font-weight: 500;
            position: relative;
            text-decoration: underline;
        }
    }

    /**
         * Custom checkbox
         * 1. Custom checkbox holder
         * 2. Custom checkbox checked indicator
         * 3. Svg is integrated inside content, icon and color can be change thru settings.
         * If you need IE11 support use colored png or svg integrated with base64.
         * 4. Hover state
         * 5. Focus state
         * 6. Checked state - size of the indicator can be controlled with scale ratio
         * 7. Disabled state
         * 8. Different checkbox indicator color for disabled state, can be changed thru settings
         */
    &__checkbox {
        & + .u-form__checkbox-label {
            @include spacing('padding-left', 's-24');

            position: relative;
            display: inline-block;
            cursor: pointer;

            @include mq(lg) {
                padding-left: rem(24px);
            }

            /* 1, 2 */
            &:before,
            &:after {
                @include spacing('width', 's-16');
                @include spacing('height', 's-16');

                content: '';
                transition: $form-transition;
                position: absolute;
                top: $form-checkbox-top-position;
                left: 0;
                display: block;

                @include mq(lg) {
                    width: rem(16px);
                    height: rem(16px);
                }
            }

            /* 1 */
            &:before {
                @if $form-checkbox-border != false {
                    border: $form-checkbox-border;

                    @include mq(null, xl) {
                        border: $form-checkbox-border-ws;
                    }
                }
                @if $form-checkbox-background-color != false {
                    background-color: $form-checkbox-background-color;
                }
                border-radius: $form-checkbox-border-radius;

                @include mq(null, xl) {
                    border-radius: get-vw($form-checkbox-border-radius);
                }
            }

            /* 2 */
            &:after {
                transform: scale(0);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-color: $white;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuNyAxLjU0M0wzLjc0MiA3LjUuMiAzLjk1NmwxLjA1Ny0xLjA0NEwzLjc0MyA1LjRsNC45LTQuOSAxLjA1NiAxLjA0M3oiIGZpbGw9IiNDRDAwMzkiLz48L3N2Zz4=');
            }
        }

        /* 4 */
        &:hover {
            &:not(:checked),
            &:checked {
                & + .u-form__checkbox-label {
                    &:before {
                        outline: 0;
                        @if $form-checkbox-hover-border != false {
                            border: $form-checkbox-hover-border;
                        }
                        @if $form-checkbox-hover-background-color != false {
                            background-color: $form-checkbox-hover-background-color;
                        }
                    }
                }
            }
        }

        /* 5 */
        &:focus {
            &:not(:checked),
            &:checked {
                & + .u-form__checkbox-label {
                    &:before {
                        outline: 0;
                        @if $form-checkbox-focus-border != false {
                            border: $form-checkbox-focus-border;
                        }
                        @if $form-checkbox-focus-background-color != false {
                            background-color: $form-checkbox-focus-background-color;
                        }
                    }
                }
            }
        }

        /* 6 */
        &:checked {
            & + .u-form__checkbox-label {
                &:before {
                    outline: 0;
                    @if $form-checkbox-checked-border != false {
                        border: $form-checkbox-checked-border;
                    }
                    @if $form-checkbox-checked-background-color != false {
                        background-color: $form-checkbox-checked-background-color;
                    }
                    border-color: $red;
                }

                &:after {
                    transform: scale(0.7);
                }
            }
        }

        /* 7 */
        &:disabled {
            & + .u-form__checkbox-label {
                color: $form-checkbox-disabled-label-color;
            }

            &:not(:checked),
            &:checked {
                & + .u-form__checkbox-label {
                    &:before {
                        @if $form-checkbox-disabled-border != false {
                            border: $form-checkbox-disabled-border;
                        }
                        @if $form-checkbox-disabled-background-color != false {
                            background-color: $form-checkbox-disabled-background-color;
                        }
                    }
                }
            }

            /* 8 */
            &:checked {
                & + .u-form__checkbox-label {
                    &:after {
                        background-color: $red;
                    }
                }
            }
        }
    }

    /**
		* Custom radio
		* 1. Custom radio holder
		* 2. Custom radio checked indicator
		* 3. Hover state
		* 4. Focus state
		* 5. Checked state - size of the indicator can be controlled with scale ratio
		* 6. Disabled state
		*/
    &__radio {
        & + .u-form__radio-label {
            @include spacing('padding-left', 's-32');

            position: relative;
            display: inline-block;
            cursor: pointer;

            @include mq(lg) {
                padding-left: rem(24px);
            }

            /* 1, 2 */
            &:before,
            &:after {
                @include spacing('width', 's-12');
                @include spacing('height', 's-12');

                content: '';
                transition: $form-transition;
                position: absolute;
                top: $form-radio-top-position;
                left: 0;
                display: block;

                @include mq(lg) {
                    width: rem(12px);
                    height: rem(12px);
                }
            }

            /* 1 */
            &:before {
                @if $form-radio-border != false {
                    border: $form-radio-border;

                    @include mq(null, xl) {
                        border: $form-radio-border;
                    }
                }
                @if $form-radio-background-color != false {
                    background-color: $form-radio-background-color;
                }
                border-radius: $form-radio-border-radius;
            }

            /* 2 */
            &:after {
                background-color: $form-radio-icon-color;
                border-radius: $form-radio-icon-border-radius;
                transform: scale(0);
            }
        }

        /* 23*/
        &:hover {
            &:not(:checked),
            &:checked {
                & + .u-form__radio-label {
                    &:before {
                        outline: 0;
                        @if $form-radio-hover-border != false {
                            border: $form-radio-hover-border;
                        }
                        @if $form-radio-hover-background-color != false {
                            background-color: $form-radio-hover-background-color;
                        }
                    }
                }
            }
        }

        /* 4 */
        &:focus {
            &:not(:checked),
            &:checked {
                & + .u-form__radio-label {
                    &:before {
                        outline: 0;
                        @if $form-radio-focus-border != false {
                            border: $form-radio-focus-border;
                        }
                        @if $form-radio-focus-background-color != false {
                            background-color: $form-radio-focus-background-color;
                        }
                    }
                }
            }
        }

        /* 5 */
        &:checked {
            & + .u-form__radio-label {
                &:before {
                    outline: 0;
                    @if $form-radio-checked-border != false {
                        border: $form-radio-checked-border;
                    }
                    @if $form-radio-checked-background-color != false {
                        background-color: $form-radio-checked-background-color;
                    }
                }

                &:after {
                    transform: scale(0.45);
                }
            }
        }

        /* 6 */
        &:disabled {
            & + .u-form__radio-label {
                color: $form-radio-disabled-label-color;
            }

            &:not(:checked),
            &:checked {
                & + .u-form__radio-label {
                    &:before {
                        @if $form-radio-disabled-border != false {
                            border: $form-radio-disabled-border;
                        }
                        @if $form-radio-disabled-background-color != false {
                            background-color: $form-radio-disabled-background-color;
                        }
                    }
                }
            }

            &:checked {
                & + .u-form__radio-label {
                    &:after {
                        background-color: $form-radio-disabled-icon-color;
                    }
                }
            }
        }
    }
}
